/** @jsx jsx */
import 'normalize.css'
import 'styles/fonts.css'
import 'styles/global.css'
import PropTypes from 'prop-types'
import {toast} from 'react-toastify'
import theme from 'styles/theme'
import {jsx, ThemeProvider} from 'theme-ui'
import Footer from './Footer'
import 'react-toastify/dist/ReactToastify.min.css'
import Nav from './Nav'

interface ILayout {
  className?: string
  children: React.ReactNode
  navMobileColor?: string
}

export type ChildrenProps = {
  loadingUser: boolean
}

toast.configure({
  position: 'top-center',
})

const Layout: React.FC<ILayout> = ({className, children, navMobileColor}) => {
  return (
    <ThemeProvider theme={theme}>
      <div
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Nav mobileColor={navMobileColor} />
        <main className={className}>{children}</main>
        <Footer />
      </div>
    </ThemeProvider>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
