/** @jsx jsx */
import {jsx} from 'theme-ui'
import Logo from 'images/components/UmmatyLogoFull.svg'
import {socials, columns} from 'data/footer'
import {Link} from 'gatsby'
import {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import {Locale} from 'i18n'

const Footer: React.FC = () => {
  const {t, i18n} = useTranslation()
  const language = i18n.language as Locale
  const homePageLink = language === 'en' ? '/' : '/fr/'
  return (
    <footer
      sx={{
        pt: [40, , 64],
        pb: [40, , 56],
        backgroundColor: '#0A343F',
        color: '#fff',
        a: {
          color: '#fff',
          textDecoration: 'none',
        },
        zIndex: 2,
      }}
    >
      <div
        sx={{
          width: ['85%', '85%', 1028],
          maxWidth: '90%',
          mx: 'auto',
          display: 'flex',
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        <div sx={{flex: 1}}>
          <Link to={homePageLink}>
            <Logo
              sx={{
                height: 36,
                mb: 17,
                '.primary': {
                  fill: '#fff',
                },
                '.secondary': {
                  fill: '#055064',
                },
              }}
            />
          </Link>
          <div sx={{fontSize: [14, , 12]}}>{t('registrationNumber')}</div>
          <ul
            sx={{
              listStyle: 'none',
              pl: 0,
              mb: 0,
              mt: 28,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {socials.map(({href, icon: Icon, name}) => (
              <li key={name} sx={{mr: [35, , 22]}}>
                <a href={href} target="blank" rel="noopener noreferrer">
                  <Icon
                    sx={{
                      width: [28, , 20],
                      height: [28, , 20],
                    }}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div
          id="footer__links"
          sx={{
            maxWidth: 365,
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: ['column', 'column', 'row'],
          }}
        >
          {columns.map(({title, items}) => (
            <div
              key={title}
              sx={{
                mt: [47, 47, 0],
              }}
            >
              <h3
                sx={{
                  my: 0,
                  fontSize: [18, 18, 14],
                  marginBottom: 16,
                  color: '#fff',
                }}
              >
                {t(title)}
              </h3>
              <ul
                sx={{
                  listStyle: 'none',
                  pl: 0,
                  my: 0,
                }}
              >
                {items.map((item) => {
                  const content =
                    'pre' in item ? (
                      <Fragment>
                        <span sx={{fontWeight: 300}}>{item.pre} &nbsp;</span>
                        {t(item.name)}
                      </Fragment>
                    ) : (
                      t(item.name)
                    )
                  return (
                    <li
                      key={item.name}
                      sx={{
                        marginTop: 12,
                        fontSize: [18, 18, 14],
                      }}
                    >
                      {'href' in item ? (
                        <a
                          href={item.href}
                          target="blank"
                          rel="noopener noreferrer"
                        >
                          {content}
                        </a>
                      ) : (
                        <Link to={item.link[language]}>{content}</Link>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          ))}
        </div>
      </div>
      {/* © {new Date().getFullYear()}, All right reserved{' '}
      <a href="https://ummaty.org">Ummaty</a> */}
    </footer>
  )
}

export default Footer
