import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './hamburger.css'

type HamburgerProps = {
  className?: string
  color: string
  isActive: boolean
  toggleActive: () => void
}

const Hamburger: React.FC<HamburgerProps> = ({className, color, isActive, toggleActive}) => {
  return (
    <div
      className={cx('hamburger hamburger--spring', className, `hamburger-${color}`, {
        'is-active': isActive,
      })}
      onClick={toggleActive}
    >
      <div className="hamburger-box">
        <div className="hamburger-inner"></div>
      </div>
    </div>
  )
}

Hamburger.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  toggleActive: PropTypes.func.isRequired,
}

export default Hamburger
