/** @jsx jsx */
import {jsx} from 'theme-ui'
import {Link} from 'gatsby'
import Logo from 'images/components/UmmatyLogoFull.svg'
import LinkOrButton from 'components/LinkOrButton'
import {navLinks} from 'data/navigation'
import HeadRoom from 'react-headroom'
import Hamburger from './Hamburger'
import {useState} from 'react'
import SideMenu from './SideMenu'
import {useTranslation} from 'react-i18next'
import {Locale} from 'i18n'
import useOtherLanguageLink from 'hooks/useOtherLanguageLink'

interface INav {
  mobileColor?: string
}
const Nav: React.FC<INav> = ({mobileColor}) => {
  const [isActive, setActive] = useState(false)
  const toggleActive = () => {
    setActive(!isActive)
  }
  const {t, i18n} = useTranslation()
  const language = i18n.language as Locale
  const otherLanguageLink = useOtherLanguageLink()
  const homePageLink = language === 'en' ? '/' : '/fr/'
  return (
    <div
      sx={{
        height: 118,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <HeadRoom
        style={{
          height: 118,
        }}
        sx={{
          '.headroom--pinned': {
            background:
              mobileColor && !isActive
                ? [mobileColor, mobileColor, 'white']
                : 'white',
            boxShadow:
              '0px 2px 4px -1px rgba(0,0,0,0.08), 0px 4px 5px 0px rgba(0,0,0,0.04), 0px 1px 10px 0px rgba(0,0,0,0.08)',
          },
          bg:
            mobileColor && !isActive
              ? [mobileColor, mobileColor, 'white']
              : 'white',
          position: 'absolute',
          width: '100%',
          zIndex: 20,
        }}
      >
        <nav
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: ['100%', , 1028],
            maxWidth: [, , '90%'],
            mx: 'auto',
            pl: [28, , 0],
            pr: [13, , 0],
          }}
        >
          <Link
            to={homePageLink}
            sx={{
              opacity: isActive ? '0' : '1',
              transition: 'opacity 0.2s linear',
            }}
          >
            <Logo sx={{height: [36, 44]}} />
          </Link>

          <div
            sx={{
              display: ['none', 'none', 'flex'],
              alignItems: 'center',
            }}
          >
            {navLinks.map(({isButton, name, to}) => {
              const buttonStyles = isButton
                ? {
                    borderColor: 'primary',
                    borderRadius: 5,
                    transition: 'color, background-color 0.3s linear',
                    '&:hover': {
                      backgroundColor: 'primary',
                      color: 'white',
                    },
                    borderWidth: 1,
                    borderStyle: 'solid',
                  }
                : {border: 'none', bg: 'transparent'}
              return (
                <LinkOrButton
                  key={name}
                  to={to[language]}
                  sx={{
                    textDecoration: 'none',
                    color: 'primary',
                    /* text-decoration: underline; */
                    // backgroundColor: "#f2f2f2";
                    // color: "#085266";
                    // bordeRadius: "5px";
                    // padding: "10px 15px";
                    // cursor: "default";
                    fontSize: 14,
                    py: 10,
                    px: 15,
                    mx: 10,
                    cursor: 'pointer',
                    ...buttonStyles,
                  }}
                >
                  {t(name)}
                </LinkOrButton>
              )
            })}
            <LinkOrButton
              to={otherLanguageLink}
              sx={{
                textDecoration: 'none',
                color: 'primary',
                fontSize: 14,
                py: 10,
                px: 15,
                mx: 10,
                cursor: 'pointer',
                border: 'none',
                bg: 'transparent',
              }}
            >
              {t('otherLanguage')}
            </LinkOrButton>
          </div>
          <Hamburger
            sx={{
              display: ['block', , 'none'],
            }}
            color={'black'}
            isActive={isActive}
            toggleActive={toggleActive}
          />
        </nav>
      </HeadRoom>
      {isActive && (
        <SideMenu
          onChangeRoute={() => {
            setTimeout(() => {
              toggleActive()
            }, 500)
          }}
        />
      )}
    </div>
  )
}

export default Nav
