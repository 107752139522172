export default {
  breakpoints: ['40em', '56em', '1200px'],
  space: [],
  fontSizes: [],
  colors: {
    // primary: '#0A343F',
    primary: '#055064',
    text: '#042D37',
    background: '#fff',
    lightGrey: '#F4F7F8',
    grey: '#717A7B',
    soft: '#676F72',
    black: '#042D37',
  },
  styles: {
    p: {
      fontSize: 15,
      lineHeight: '24px',
      maxWidth: 500,
    },
  },
}
