/** @jsx jsx */
import {jsx} from 'theme-ui'
import {navLinks} from 'data/navigation'
import LinkOrButton from './LinkOrButton'
import {useTranslation} from 'react-i18next'
import useOtherLanguageLink from 'hooks/useOtherLanguageLink'
import {Locale} from 'i18n'

type SideMenuProps = {
  className?: string
  onChangeRoute: () => void
}
const SideMenu: React.FC<SideMenuProps> = ({className, onChangeRoute}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language as Locale
  const otherLanguageLink = useOtherLanguageLink()

  return (
    <div
      className={className}
      sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        overflow: '',
        zIndex: 10,
      }}
    >
      <ul
        sx={{
          mt: 110,
          height: 282,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          listStyleType: 'none',
          pl: 0,
        }}
      >
        {navLinks.map(({name, isButton, to}) => {
          const buttonStyles = isButton
            ? {
                borderRadius: 5,
                backgroundColor: 'primary',
                color: 'white',
                px: 38,
              }
            : {border: 'none', bg: 'transparent'}
          return (
            <li key={name}>
              <LinkOrButton
                to={to[language]}
                sx={{
                  display: 'inline-block',
                  color: 'primary',
                  mb: 30,
                  py: 10,
                  fontSize: 20,
                  fontWeight: 400,
                  fontFamily: 'Lato Medium',
                  textDecoration: 'none',
                  ...buttonStyles,
                }}
                onClick={onChangeRoute}
              >
                {t(name)}
              </LinkOrButton>
            </li>
          )
        })}
        <li>
          <LinkOrButton
            to={otherLanguageLink}
            sx={{
              display: 'inline-block',
              color: 'primary',
              mb: 30,
              py: 10,
              fontSize: 20,
              fontWeight: 400,
              fontFamily: 'Lato Medium',
              textDecoration: 'none',
              border: 'none',
              bg: 'transparent',
            }}
            onClick={onChangeRoute}
          >
            {t('otherLanguage')}
          </LinkOrButton>
        </li>
      </ul>
    </div>
  )
}

export default SideMenu
